@import 'styles/includes';

.Hero {
    $root: &;

    position: relative;
    display: flex;
    align-items: flex-end;
    min-height: 480px;
    overflow: hidden;
    padding: calc($spacingL + $spacingM) 0;

    &--NoImage {
        background-color: $colorGray10;
    }

    &--Small {
        min-height: 220px;
        padding: 48px 0 24px 0;
    }

    &--White {
        background-color: $colorWhite;
    }

    &--White#{&}--Small {
        min-height: 0;
        padding: 64px 0 0;
        display: block;
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        background: linear-gradient(360deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 33%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 100%)
    }

    &:before {
        content: '';
        background-color: $colorRed70;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: -2;
    }

    @include media(m) {
        min-height: 612px;
        padding: calc($spacingL * 2) 0;

        &--Small {
            min-height: 220px;
            padding: 64px 0 48px 0;
        }

        &--White#{&}--Small {
            min-height: 0;
            padding: 64px 0 0;
        }
    }

    &--Products {
        min-height: 550px;

        @include media(m) {
            min-height: 455px;
        }
    }

    &__Title {
        @extend %h1;
        color: $colorWhite;
        font-family: $fontLight;
        width: 100%;
        margin-bottom: calc($spacingM + $spacingS);

        #{$root}--NoImage & {
            color: $colorBlack;
            @extend %h2;
        }

        #{$root}--Small & {
            margin-bottom: 16px;
        }

        @include media(ml) {
            margin-bottom: 0;
            max-width: 631px;

            #{$root}--Small & {
                margin-bottom: 16px;
            }
        }

        #{$root}--Products & {
            font-size: 30px;

            @include media(m) {
                font-size: 40px;
            }
        }
    }

    &__Description {
        font-family: $fontLight;
        color: $colorWhite;
        line-height: 1.35;
        font-size: 1.8rem;
        margin: 0 0 24px 0;

        #{$root}--NoImage & {
            color: $colorBlack;
        }

        #{$root}--Small & {
            margin: 0;
        }

        @include media(ml) {
            max-width: 631px;
            font-size: 2.0rem;
            margin: 24px 24px -8px 0;

            #{$root}--Small & {
                margin: 0;
            }
        }
    }

    &__Container {
        @extend %container;
    }

    &__Row {
        @include media(ml) {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
        }
    }

    &__Links {
        display: flex;
        flex-wrap: wrap;

        #{$root}--Small & {
            margin-top: 24px;
        }

        @include media(m) {
            flex-wrap: nowrap;
        }

        @include media(ml) {
            #{$root}--Small & {
                margin-top: 0;
            }
        }
    }

    &__Link {
        &:first-of-type {
            margin-right: calc($spacingM + $spacingS);
            margin-bottom: $spacingM;
        }

        @include media(m) {
            &:first-of-type {
                margin-bottom: 0;
            }
        }
    }
}