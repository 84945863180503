@import '../../styles/includes';

.StreamField {
    &__Component {
        padding: 32px 0;

        @include media(m) {
            padding: 72px 0;
        }

        &--AudioPlayerList,
        &--ThreeColumnModule,
        &--Accordion,
        &--ImageVideoCarousel,
        &--Banner {
            padding: 0;
        }

        &--SoftwareCardList,
        &--ProductCardList,
        &--ProductLogoImage {
            padding: 32px 0;

            @include media(m) {
                padding: 48px 0;
            }
        }

        &--RichText,
        &--ProductLogoImage,
        &--ArtistNameList {
            padding: 16px 0;

            @include media(m) {
                padding: 32px 0;
            }
        }

        &--ProductHighlightItem
         {
            padding-bottom: 0;
        }

        &--ProductsList {
            padding: 64px 0;

            @include media(m) {
                padding: 72px 0;
            }
        }

        &--PlayableAudioCardList {
            padding: 0;
        }

        &--RichText,
        &--ProductLogoImage,
        &--ReleaseImageCardList,
        &--ArtistNameList {
            padding-top: 0;
        }
    }
}